<template>
  <div id="product-listing" class="product-listing mt30 row start-xs start-md">
    <div
      v-for="(product, index) in products"
      :key="product.id"
      class="flex product-listing__wrapper center-xs"
      :class="columnsClass()"
    >
      <product-tile
        :product="product"
        :list="currentCategoryName"
        :subscription="parsedSubscription"
        :columns="columns"
        :position="index"
        :recently-view="recentlyView"
      />
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import SubscriptionDataMixin from 'theme/mixins/subscriptionDataMixin'

export default {
  name: 'ProductListing',
  mixins: [SubscriptionDataMixin],
  components: {
    ProductTile
  },
  props: {
    currentCategoryName: {
      type: String,
      required: false,
      default: 'catalog'
    },
    products: {
      type: null,
      required: true
    },
    columns: {
      type: [Number, String],
      required: true
    },
    filterToogle: {
      type: Boolean,
      default: false
    },
    recentlyView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    columnsClass () {
      return this.columns === 'wishlist'
        ? 'col-md-4 col-xs-6' : this.columns === 'on'
          ? 'col-xs-6 col-sm-4 col-md-3'
          : 'col-xs-12'
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-listing {
    @media (max-width: 767px) {
      margin: 0;
      &__wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
</style>
